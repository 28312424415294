
// .form-control {
//   border-radius: 1.25rem !important;
// }

.color-padrao{
  color: #3399cc;
}
.color-padrao:hover{
  color: #277399;
}

.form-imput {
  display: block;
  width: 60%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-imput2 {
  display: block;
  width: 70%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-imput3 {
  display: block;
  width: 95%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.center{
  text-align: center;
}

.footer{
  
    bottom: 0;
    left: 30%;
    right: -50%;
    margin-top: 22%;
}

.linha {
  display: block;
clear: both;
}
.texto-novo{
  font-size: 18px;
  line-height: 25px;
  font-weight: 300;
}
.texto-novo2{
    font-size: 25px;
    font-family: Segoe UI Symbol, Arial, Helvetica, sans-serif;
    margin-top: 2%;
    color: #edf8f5;
}
.bg-footer{
  background-color: #081225;
  width: 100%;
  text-align: center;
}

.control-imp{
    float: left;
    display: block;
    width: 82%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-right: 1px;
  }

.control-bnt{
    float: left;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
     -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    /* border-radius: .25rem; */
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: #3399cc!important;
    border-color: #3399cc!important;
    cursor: pointer;
}
.control-bnt:hover{
background-color: #192846;
}
.form-signin
{
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}
.form-signin .form-signin-heading, .form-signin .checkbox
{
    margin-bottom: 10px;
}
.form-signin .checkbox
{
    font-weight: normal;
}
.form-signin .form-control
{
  
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
}
.form-signin .form-control:focus
{
    z-index: 2;
}
.form-signin input[type="text"]
{
    margin-bottom: 10px;
}
.form-signin input[type="password"]
{
    margin-bottom: 10px;
}
.form-signin input[type="email"]
{
    margin-bottom: 10px;
}
.account-wall
{
    margin-top: 20px;
    padding: 40px 0px 20px 0px;
    background-color: #edf8f5;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.login-title
{
    color: #555;
    font-size: 18px;
    font-weight: 400;
    display: block;
}
.profile-img
{
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.need-help
{
    margin-top: 10px;
}
.new-account
{
    display: block;
    margin-top: 10px;
}

.login-alert-message
{
  text-align: left;
  color: #e81123;
  font-family: "Segoe UI Webfont",-apple-system,"Helvetica Neue","Lucida Grande","Roboto","Ebrima","Nirmala UI","Gadugi","Segoe Xbox Symbol","Segoe UI Symbol","Meiryo UI","Khmer UI","Tunga","Lao UI","Raavi","Iskoola Pota","Latha","Leelawadee","Microsoft YaHei UI","Microsoft JhengHei UI","Malgun Gothic","Estrangelo Edessa","Microsoft Himalaya","Microsoft New Tai Lue","Microsoft PhagsPa","Microsoft Tai Le","Microsoft Yi Baiti","Mongolian Baiti","MV Boli","Myanmar Text","Cambria Math";
  font-weight: 400;
  font-size: .9375rem;
  line-height: 1.25rem;
}

.identity {
    line-height: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    width: calc(100% - 56px);
}

.identity-banner {
    height: 28px;
    margin: 16px -36px;
    padding: 0 36px;
}

.profile-photo {
    height: 48px;
    width: 48px;
    margin-top: -48px;
    border-radius: 50%;
    overflow: hidden;
    float: right;
}

/* css by pwarick spinner */

.sk-circle {
  margin: 100px auto;
  position: fixed;
  z-index: 999;
  height: 100px;
  width: 100px;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 20%;
  right: 0;
}

.sk-circle-text {
  margin: 100px auto;
  position: fixed;
  z-index: 999;
  height: 100px;
  width: 50%;
  overflow: show;
  margin: auto;
  top: 10;
  left: 0;
  bottom: 70%;
  right: 0;
  color: #e9ecef;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #e9ecef;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.user-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: arial;
}

.user-card-title {
  color: grey;
  font-size: 18px;
}

.user-card-button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.user-card-a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    padding: 24px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

tr:hover {background-color:#EDF8F5;}

/* css by pwarick notification */

.bell{
  
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}


/* WHATSAPP FIXO */
.whatsapp-fixo {
position: fixed;
bottom:50px;
right:2%;
z-index: 999;
}

@keyframes fa-blink {
     0% { opacity: 1; }
     50% { opacity: 0.5; }
     100% { opacity: 0; }
 }
.fa-blink {
   -webkit-animation: fa-blink .95s linear infinite;
   -moz-animation: fa-blink .95s linear infinite;
   -ms-animation: fa-blink .95s linear infinite;
   -o-animation: fa-blink .95s linear infinite;
   animation: fa-blink .95s linear infinite;
}

.mark {

  background-color: rgba(255, 238, 115, 0.59);
}

/* CONFIGURAÇÕES DA PAGINA */

html, body {
  background: linear-gradient(180deg, #192846 0%, #192846 53%, #192846 100%);
  }

a.button {
  background-color: #26d367;
    border: 0;
    padding: 30px 40px 8px 40px;
    border-radius: 40px;
    cursor: pointer;
    outline: 0;
    margin-top: 10px;
  }
a.button2 {
  background-color: #3399cc;
    border: 0;
    padding: 30px 40px 8px 40px;
    border-radius: 40px;
    cursor: pointer;
    outline: 0;
    margin-top: 10px;
}

.text{
  font-size: 25px;
  font-family: Segoe UI Symbol, Arial, Helvetica, sans-serif;
  margin-top: 2%;
  color: #edf8f5;
  padding-left: 2%;
  padding-right: 2%;
}
h6.title{
  font-size: 18px;
  font-family: Segoe UI Symbol, Arial, Helvetica, sans-serif;
  margin-top: 2%;
  color: #edf8f5;
  padding-left: 2%;
  padding-right: 2%;
  text-align: left;
}
h5.botaoTelegram{
  font-size: 18px;
  font-family: Segoe UI Symbol, Arial, Helvetica, sans-serif;
  margin-top: 2%;
  color: #edf8f5;
  padding-left: 2%;
  padding-right: 2%;
  text-align: left;
}

.bannerEsq, .bannerDir {
  width: 100%;
  height: 700px;
  text-align: center;
}
.bannerEsq{
  // background-image: url('../../images/bg-esq.jpg') ;
  background-position: center;
  position: static;
  background-repeat: no-repeat;
}
.bannerDir{  
  // background-image: url('../../images/bg-dir.jpg') ;
  background-position: right;
  background-repeat: no-repeat;
  position: static;
}

.margin{
  padding-left: 10%;
  padding-right: 10%;
}

 .col-div-dir {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1%!important;
  padding-left: 1%!important;
}

.col-div-esq {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1%!important;
  padding-left: 1%!important;
}

.span-text{
  font-size: 12px;
  font-weight: 300;
  color: rgb(124, 124, 124);
  text-align: justify;
}

.func {
  position: relative;
  display: block;  
  margin-bottom: -1px;
  /* background-color: #fff; */
  // border: 1px solid rgba(0,0,0,.125);
  color: #fff;
  line-height: 43px;
  text-align: left;
  font-weight: 100;
  font-size: 18px;
}

.span-text{
  padding-left: 3%;
  padding-right: 4%;
}

.num {
  display: flex;
   align-items: center;
   justify-content: center;
   width: 30px;
   height: 30px;
   border-radius: 50%;
   float: left;
   margin: 4px;
   font-size: 30px;
   font-weight: bold;
   color: #555;

   background: -moz-linear-gradient(270deg, #f5f5f5 0%, #f5f5f5 16%, #999999 100%);
   background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f5f5f5), color-stop(16%, #f5f5f5), color-stop(100%, #999999));
   background: -webkit-linear-gradient(270deg, #f5f5f5 0%, #f5f5f5 16%, #999999 100%);
   background: -o-linear-gradient(270deg, #f5f5f5 0%, #f5f5f5 16%, #999999 100%);
   background: -ms-linear-gradient(270deg, #f5f5f5 0%, #f5f5f5 16%, #999999 100%);
   background: linear-gradient(180deg, #f5f5f5 0%, #f5f5f5 16%, #999999 100%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#999999',GradientType=0 );
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#999999',GradientType=0 );

   -webkit-box-shadow: 2px 2px 2px 0px rgba(50, 50, 50, 0.75);
   -moz-box-shadow:    2px 2px 2px 0px rgba(50, 50, 50, 0.75);
   box-shadow:         2px 2px 2px 0px rgba(50, 50, 50, 0.75);
}

.esp-line{
  line-height: 1.5;
}

.disp{
  display: flex;
}

.border-r{
  border-right: solid 1px #22365e;
}

.color-fff{
  color: #fff;
}
.centro{
  text-align: center;
}

@media (max-width: 768px){

  

  .bannerEsq, .bannerDir {
    width: 100%;
    height: auto;
    text-align: center;
}
  

}

/*--------------------- MODAL------------------------------ */

.btn-modal {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: pre-wrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.modal-footer-n {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0 0 10px 0;
  // border-top: 1px solid #e9ecef;
}

.modal-header-n {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  // border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.card-n {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  // border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.btn-lg-2 {
  /* padding: .5rem 1rem; */
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

.list-group-item-n {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #EAEAEA;
  // border: 1px solid rgba(170, 37, 37, 0.125);
}
.list-group-item-n:hover {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  // background-color: none!important;
  // border: 1px solid rgba(170, 37, 37, 0.125);
}

.modal-content-n {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #eaeaea;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
}
.form-control-n {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ffffff;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (min-width: 992px){
  .modal-lg2 {
    max-width: 500!important;
}

}

